// src/pages/DashboardPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './DashboardPage.css'; // 引入樣式

const DashboardPage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({}); // 用來存儲用戶的基本信息

  // 模擬從後端獲取用戶資料
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
        navigate('/login'); // 如果未登入，跳回登入頁面
      }
    };

    fetchUserData();
  }, [navigate]);

  return (
    <div className="dashboard-container">
      <h1>歡迎，{user.name}</h1>
      <h2>這是你的 Dashboard</h2>

      <div className="dashboard-options">
        <button onClick={() => navigate('/profile')} className="dashboard-button">
          查看/編輯個人資料
        </button>
        <button onClick={() => navigate('/matches')} className="dashboard-button">
          查看配對推薦
        </button>
        <button onClick={() => navigate('/chat')} className="dashboard-button">
          開啟聊天
        </button>
        <button onClick={() => navigate('/notifications')} className="dashboard-button">
          查看通知
        </button>
      </div>
    </div>
  );
};

export default DashboardPage;

// src/pages/LoginPage.js
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { login } from '../services/authService';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css'; // 可以將樣式放入這個文件中

const LoginPage = () => {
  const navigate = useNavigate();

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-header">
          <h1>歡迎回來</h1>
          <p>登入以繼續使用交友平台</p>
        </div>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={Yup.object({
            email: Yup.string().email('無效的電子郵件').required('必填'),
            password: Yup.string().min(8, '密碼至少需要8個字符').required('必填'),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const response = await login(values.email, values.password);
              localStorage.setItem('token', response.data.token); // 保存 JWT
              alert('登入成功！');
              navigate('/dashboard'); // 登入成功後跳轉到 dashboard
            } catch (error) {
              console.error(error);
              alert('登入失敗，請檢查您的憑證');
            }
            setSubmitting(false);
          }}
        >
          <Form className="login-form">
            <div className="form-group">
              <label htmlFor="email">電子郵件</label>
              <Field name="email" type="email" className="form-control" />
              <ErrorMessage name="email" component="div" className="error" />
            </div>

            <div className="form-group">
              <label htmlFor="password">密碼</label>
              <Field name="password" type="password" className="form-control" />
              <ErrorMessage name="password" component="div" className="error" />
            </div>

            <button type="submit" className="login-button">登入</button>
          </Form>
        </Formik>
        <div className="login-footer">
          <p>還沒有帳號？ <a href="/signup">立即註冊</a></p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

// src/pages/RegisterPage.js
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import './RegisterPage.css'; // 引入樣式文件

const RegisterPage = () => {
  return (
    <div className="register-container">
      <div className="register-card">
        <div className="register-header">
          <h1>創建帳號</h1>
          <p>加入我們的交友平台，開始結交新朋友！</p>
        </div>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={Yup.object({
            email: Yup.string().email('無效的電子郵件').required('必填'),
            password: Yup.string().min(8, '密碼至少需要8個字符').required('必填'),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const response = await axios.post('/api/register', values);
              console.log(response.data);
              alert('註冊成功！');
            } catch (error) {
              console.error(error);
              alert('註冊失敗');
            }
            setSubmitting(false);
          }}
        >
          <Form className="register-form">
            <div className="form-group">
              <label htmlFor="email">電子郵件</label>
              <Field name="email" type="email" className="form-control" />
              <ErrorMessage name="email" component="div" className="error" />
            </div>

            <div className="form-group">
              <label htmlFor="password">密碼</label>
              <Field name="password" type="password" className="form-control" />
              <ErrorMessage name="password" component="div" className="error" />
            </div>

            <button type="submit" className="register-button">註冊</button>
          </Form>
        </Formik>
        <div className="register-footer">
          <p>已經有帳號了嗎？ <a href="/login">立即登入</a></p>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;

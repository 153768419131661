// src/services/authService.js
import axios from 'axios';

const API_URL = 'http://18.136.26.230/api'; // 替換爲你的 EC2 IP 地址

export const login = (email, password) => {
  return axios.post(`${API_URL}/login`, { email, password });
};

export const register = (email, password) => {
  return axios.post(`${API_URL}/register`, { email, password });
};
